<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <v-container class="elevation-6 mb-6">
      <v-row>
        <v-col class="mx-auto mt-10 font-weight-regular">
          <h2>WHO WE ARE</h2>
          <p class="mt-5">
            Christore Limited is Nigeria’s leading bookstore. The company began
            operations in 2010. Presently, the business has wholesale and retail
            operations in multiple locations.
          </p>

          <p>
            Our headquarters in Ado has the largest collection of books in
            Nigeria. Our stores have an inviting book environment and a wide
            variety of local and international books on display.
          </p>

          <p>
            We represent many of the world’s leading publishers in Nigeria and
            so we have books for all ages as well as stationery for home, school
            and office use. In addition to book selling, we provide library
            consultancy services to institutions and we have a publishing
            division.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
export default {
  name: "About",
  components: { BreadCrumb },
  data() {
    return {
      items: [
        {
          text: "Home",
          disabled: false,
          link: "Home",
        },
        {
          text: "About",
          disabled: true,
          link: "About",
        },
      ],
    };
  },
};
</script>
